import React from 'react';
const config = require('../data.json');

function TestPage(props) {
  const {pageContext} = props;

  return (
    <div>
      <h2>Project Config</h2>
      <p>{JSON.stringify(config, null, 2)}</p>
    </div>
  );
}

export default TestPage;
